<template>
  <div>
    <top-nav :showLogo="false" :title="$t('存储')"></top-nav>
    <div class="container" style="margin-top: 2rem">
      <div class="balance-cont">
        <div class="balance">
          {{ $t('钱包余额') }}:{{ balanceOf }} BUR
        </div>

        <div class="input-cont">
          <input type="text" :placeholder="$t('请输入')" v-model="amount" @focus="promptText =''"/>
          <span>BUR</span>
          <img v-if="amount" @click="emptyValue" class="empty-icon"
               src="../../assets/image/rwa/empty-icon.png">
        </div>
        <div class="all-but">
          <span @click="allAmount">{{ $t('全部') }}</span>
        </div>

      </div>
    </div>

    <span v-if="amount !== ''">
      <div class="details-title">{{ $t('交易详情') }}</div>
      <div class="container" style="padding: 0.3rem 1.3rem">
      <div class="details-item">
        <div class="key">{{ $t('储蓄APY') }}</div>
        <div class="value">0.5%</div>
      </div>
      <div class="details-item">
        <div class="key">{{ $t('活期存款') }}</div>
        <div class="value">{{ amount }} BUR</div>
      </div>
      <div class="details-item">
        <div class="key">{{ $t('存储本金') }}</div>
        <div class="value">{{ amount }} BUR</div>
      </div>
      <div class="details-item">
        <div class="key">{{ $t('保险费用') }}</div>
        <div class="value">{{ insurance_costs }}</div>
      </div>
    </div>

    <div class="total-cont">
      <div class="key">{{ $t('总计') }}</div>
      <div class="value">{{ total_amount }} BUR</div>
    </div>

    <div class="tips">
      {{ $t('根据保险协议,本次储蓄将收额外收取储蓄本金的0.5%') }}
    </div>
    </span>
    <div style="height: 5.5rem"></div>
    <div class="bottom_cont">
      <div class="_but" @click="depositCurrent">
        {{ $t('确定') }}
      </div>
    </div>

    <div class="operation-success" v-if="showOperationSuccess">
      <div class="success-info-cont">
        <div class="success-info">
          <div class="title">{{ $t('存入成功') }}</div>
          <div class="text">{{ amount }} BUR</div>
        </div>
        <img class="_bg" src="../../assets/image/index/success-bg.png">

        <div class="_but" @click="onShowActivationSuccess">
          {{ $t('确定') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {keepDecimal} from "@/utils/tools";

export default {
  name: "storageCurrent",
  components: {TopNav},
  data() {
    return {
      account: null,
      amount: '',//存入数量
      balanceOf: '-',
      showOperationSuccess: false,
      promptText: '',
      swapBur: 0,
      rate: 0.005,
      total_amount: 0,
      insurance_costs: 0
    }
  },
  watch: {
    'amount': function (newData) {
      this.insurance_costs = keepDecimal((newData * this.rate).toString()) //保险费用
      const temp_num = (parseFloat(newData * this.rate) + parseFloat(this.amount))
      this.total_amount = keepDecimal(temp_num.toString()) // 总计
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.amount = ''
      await this.getAccount()
      this.balanceOf = await this.$store.dispatch('bur/getBalanceOf', this.account)
      await this.getAllowanceBurSwap()
    },
    async getAccount() {
      await this.$store.dispatch('bur/getAccount')
          .then(res => {
            if (res) {
              this.account = res
            }
          })
    },
    async getAllowanceBurSwap() {
      let that = this;
      this.$store.dispatch('bur/getAllowanceBurSwap', that.account)
          .then((res) => {
            console.log('getSwapBur1111111----', res)
            this.swapBur = res
          })

          .finally(() => {
            this.$toast.clear()
          })
    },
    async authorizedTransactionSwap(account, bur, amount) {
      let that = this;
      that.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('bur/authorizedTransactionSwapBur', {account: account, number: bur})
          .then((res) => {
            console.log('authorizedTransactionSwapBur----', res)
            if (res.status === true) {
              setTimeout(() => {
                that.toDepositCurrent(account, amount)
              }, 300)
            }
          })
          .catch(err => {
            setTimeout(() => {
              that.$toast(that.$t("操作失败"));
            }, 300)
            console.log(err);
          })
          .finally(() => {
            that.$toast.clear()
          })
    },

    toDepositCurrent(account, bur) {
      let that = this
      that.$toast.loading({
        message: '',
        duration: 0,
      });

      this.$store.dispatch('bur/depositCurrent',
        {"account": account, "amount": bur})
        .then(res => {
          console.log('toDepositCurrent------', res);
          that.onShowActivationSuccess()
        })
        .catch(err => {
          setTimeout(() => {
            that.$toast(that.$t("操作失败"));
          }, 300)
          console.log(err);
        })
        .finally(() => {
          that.$toast.clear()
        })
    },

    async depositCurrent() {
      let that = this;

      if (!that.amount) {
        that.$toast(that.$t("请输入正确的数量"));
        return;
      }

      if (parseFloat(this.balanceOf) < parseFloat(this.total_amount)) {
        that.$toast(that.$t("总计不能大于钱包额度"));
        return;
      }

      if (this.swapBur < that.total_amount) {
        that.authorizedTransactionSwap(that.account, that.total_amount, that.amount)
      } else {
        that.toDepositCurrent(that.account, that.amount)
        console.log('that.toDepositCurrent(that.account, that.amount)', that.amount)
      }

    },
    onShowActivationSuccess() {
      this.showOperationSuccess = !this.showOperationSuccess
      if (!this.showOperationSuccess) {
        this.init()
      }
    },
    emptyValue() {
      this.amount = ''
      this.promptText = ''
    },
    allAmount() {
      this.amount = this.balanceOf
    }
  }
}
</script>

<style scoped lang="less">
.container {
  margin: 0.7rem 4vw 0.7rem 4vw;
  padding: 1.6rem 1.3rem;
  background: #1B1B1B;
  border-radius: 1rem;
}


.balance-cont {
  padding: 1rem;
  background: #101010;
  border-radius: 0.71rem;

  .balance {
    font-size: 0.79rem;
    color: #6E6045;
  }

  .input-cont {
    text-align: right;
    padding-top: 1.5rem;

    input {
      width: 60%;
      text-align: right;
      font-size: 1rem;
      color: #fff;
      border: none;
      background-color: transparent;
    }

    input::-webkit-input-placeholder {
      color: #6E6045;
    }

    span {
      padding-left: 1.5rem;
      font-size: 0.79rem;
      color: #6E6045;
    }
    .empty-icon {
      margin-left: 0.2rem;
      float: right;
      width: 1.29rem;
    }
  }
  .all-but{
    font-size: 0.82rem;
    color: #6E6045;
  }
}

.details-title {
  padding-top: 1rem;
  margin: 0 2.5rem;
  font-size: 1.07rem;
  color: #E7BD71;
}

.details-item {
  overflow: hidden;
  padding: 1.3rem 0;
  border-bottom: 1px solid #262526;

  .key {
    float: left;
    font-size: 0.93rem;
    color: #6E6045;
  }

  .value {
    float: right;
    font-size: 1.07rem;
    color: #B5904E;
  }
}

.details-item:last-child {
  border-bottom: none;
}

.total-cont {
  overflow: hidden;
  margin: 0.7rem 4vw 0.7rem 4vw;
  padding: 1.1rem 1.3rem;
  background: #1B1B1B;
  border-radius: 1rem;


  .key {
    float: left;
    font-size: 1.07rem;
    color: #B5904E;
  }

  .value {
    float: right;
    font-size: 1.07rem;
    font-weight: bold;
    color: #B5904E;
  }
}

.tips {
  text-align: center;
  font-size: 0.86rem;
  color: #625F5F;
}

.bottom_cont {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 5rem;
  background: #151515;
  padding: 1.1rem 0.9rem;
  box-sizing: border-box;

  ._but {
    height: 2.86rem;
    line-height: 2.86rem;
    color: #000000;
    font-size: 1.14rem;
    text-align: center;
    background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.43rem;
  }
}
</style>
